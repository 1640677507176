import { IRoute } from "@/types";
import { TRAIN_TYPE } from ".";
import { STATIONS } from "./stations";
import { AREAS } from "./area";

export const ROUTES: IRoute[] = [
  {
    id: 0,
    from: STATIONS.TOKYO,
    to: STATIONS.SHIN_OSAKA,
    price: 13870,
    img: "/routes/osaka-shinkansen.webp",
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[0],
    popular: true,
  },
  {
    id: 1,
    from: STATIONS.TOKYO,
    to: STATIONS.KYOTO,
    price: 13320,
    img: "/routes/kyoto-shinkansen.webp",
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[0],
    popular: true,
  },
  {
    id: 2,
    from: STATIONS.TOKYO,
    to: STATIONS.NAGOYA,
    price: 10560,
    img: "/routes/nagoya-japan-shinkansen-v1.webp",
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[0],
    popular: true,
  },
  {
    id: 3,
    from: STATIONS.TOKYO,
    to: STATIONS.NAGANO,
    price: 7810,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[0],
  },
  {
    id: 4,
    from: STATIONS.SHINAGAWA,
    to: STATIONS.SHIN_OSAKA,
    price: 13870,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[0],
  },
  {
    id: 5,
    from: STATIONS.TOKYO,
    to: STATIONS.HAKATA,
    price: 22220,
    img: "/routes/hakata-shinkansen.webp",
    type: TRAIN_TYPE.SHINKANSEN,
    popular: true,
  },
  {
    id: 6,
    from: STATIONS.SHIN_OSAKA,
    to: STATIONS.KYOTO,
    price: 1340,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[1],
  },
  {
    id: 7,
    from: STATIONS.SHIN_OSAKA,
    to: STATIONS.TSURUGA,
    price: 4900,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[1],
  },
  {
    id: 8,
    from: STATIONS.SHIN_OSAKA,
    to: STATIONS.KANAZAWA,
    price: 4900,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[1],
  },
  {
    id: 9,
    from: STATIONS.OSAKA,
    to: STATIONS.TAKAYAMA,
    price: 7920,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[1],
  },
  {
    id: 10,
    from: STATIONS.SHIN_OSAKA,
    to: STATIONS.KINOSAKIONSEN,
    price: 6140,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[1],
  },
  {
    id: 11,
    from: STATIONS.SHIN_OSAKA,
    to: STATIONS.TOKYO,
    price: 13870,
    img: "/routes/tokyo-shinkansen.webp",
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[1],
    popular: true,
  },
  {
    id: 12,
    from: STATIONS.SHIN_OSAKA,
    to: STATIONS.HIROSHIMA,
    price: 9890,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[1],
  },
  {
    id: 13,
    from: STATIONS.SHIN_OSAKA,
    to: STATIONS.SHINAGAWA,
    price: 13870,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[1],
  },
  {
    id: 14,
    from: STATIONS.SHIN_OSAKA,
    to: STATIONS.NAGOYA,
    price: 5940,
    img: "/routes/nagoya-japan-shinkansen-v1.webp",
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[1],
    popular: true,
  },
  {
    id: 15,
    from: STATIONS.SHIN_OSAKA,
    to: STATIONS.ODAWARA,
    price: 12320,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[1],
  },
  {
    id: 16,
    from: STATIONS.SHIN_OSAKA,
    to: STATIONS.MISHIMA,
    price: 11770,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[1],
  },
  {
    id: 17,
    from: STATIONS.KYOTO,
    to: STATIONS.TOKYO,
    price: 13320,
    img: "/routes/tokyo-shinkansen.webp",
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[2],
    popular: true,
  },
  {
    id: 18,
    from: STATIONS.KYOTO,
    to: STATIONS.MISHIMA,
    price: 10780,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[2],
  },
  {
    id: 19,
    from: STATIONS.KYOTO,
    to: STATIONS.SHINAGAWA,
    price: 13320,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[2],
  },
  {
    id: 20,
    from: STATIONS.KYOTO,
    to: STATIONS.ODAWARA,
    price: 11770,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[2],
  },
  {
    id: 21,
    from: STATIONS.KYOTO,
    to: STATIONS.SHIN_OSAKA,
    price: 1450,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[2],
  },
  {
    id: 22,
    from: STATIONS.KYOTO,
    to: STATIONS.NAGOYA,
    price: 5170,
    img: "/routes/nagoya-japan-shinkansen-v1.webp",
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[2],
    popular: true,
  },
  {
    id: 23,
    from: STATIONS.KYOTO,
    to: STATIONS.HIROSHIMA,
    price: 10770,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[2],
  },
  {
    id: 24,
    from: STATIONS.KYOTO,
    to: STATIONS.TSURUGA,
    price: 3620,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[2],
  },
  {
    id: 25,
    from: STATIONS.KYOTO,
    to: STATIONS.SHIN_YOKOHAMA,
    price: 12650,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[2],
  },
  {
    id: 26,
    from: STATIONS.KYOTO,
    to: STATIONS.OKAYAMA,
    price: 7140,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[2],
  },
  {
    id: 27,
    from: STATIONS.NARITA_T1,
    to: STATIONS.TOKYO,
    price: 3070,
    img: "/routes/narita-international-airport.webp",
    type: TRAIN_TYPE.CONVENTIONAL_LINE,
    popular: true,
  },
  {
    id: 28,
    from: STATIONS.TOKYO,
    to: STATIONS.NARITA_T1,
    price: 3070,
    img: "/routes/narita-international-airport.webp",
    type: TRAIN_TYPE.CONVENTIONAL_LINE,
    popular: true,
  },
  {
    id: 29,
    from: STATIONS.SHINJUKU,
    to: STATIONS.KAWAGUCHIKO,
    price: 4130,
    img: "/routes/kawaguchiko-japan-shinkansen.webp",
    type: TRAIN_TYPE.CONVENTIONAL_LINE,
    area: AREAS[0],
    popular: true,
  },
  {
    id: 30,
    from: STATIONS.TOKYO,
    to: STATIONS.KANAZAWA,
    price: 13850,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[0],
  },
  {
    id: 31,
    from: STATIONS.TOKYO,
    to: STATIONS.IIYAMA,
    price: 8250,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[0],
  },
  {
    id: 32,
    from: STATIONS.TOKYO,
    to: STATIONS.KARUIZAWA,
    price: 5490,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[0],
  },
  {
    id: 33,
    from: STATIONS.TOKYO,
    to: STATIONS.SENDAI,
    price: 10560,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[0],
  },
  {
    id: 34,
    from: STATIONS.TOKYO,
    to: STATIONS.ODAWARA,
    price: 2540,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[0],
  },
  {
    id: 35,
    from: STATIONS.TOKYO,
    to: STATIONS.ECHIGO_YUZAWA,
    price: 6260,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[0],
  },
  {
    id: 36,
    from: STATIONS.TOKYO,
    to: STATIONS.HIROSHIMA,
    price: 18380,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[0],
  },
  {
    id: 37,
    from: STATIONS.TOKYO,
    to: STATIONS.SHIN_HAKODATE,
    price: 23630,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[0],
  },
  {
    id: 38,
    from: STATIONS.UENO,
    to: STATIONS.NAGANO,
    price: 7600,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[0],
  },
  {
    id: 39,
    from: STATIONS.UENO,
    to: STATIONS.ECHIGO_YUZAWA,
    price: 6050,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[0],
  },
  {
    id: 40,
    from: STATIONS.SHINAGAWA,
    to: STATIONS.KYOTO,
    price: 13320,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[0],
  },
  {
    id: 41,
    from: STATIONS.SHINAGAWA,
    to: STATIONS.NARITA_T1,
    price: 3250,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[0],
  },
  {
    id: 42,
    from: STATIONS.SHINAGAWA,
    to: STATIONS.NAGOYA,
    price: 10560,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[0],
  },
  {
    id: 43,
    from: STATIONS.SHINJUKU,
    to: STATIONS.HAKUBA,
    price: 8050,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[0],
  },
  {
    id: 44,
    from: STATIONS.SAPPORO,
    to: STATIONS.HAKODATE,
    price: 8910,
    img: "/routes/hakodate-japan-shinkansen.webp",
    type: TRAIN_TYPE.CONVENTIONAL_LINE,
    popular: true,
  },
  {
    id: 45,
    from: STATIONS.NAGOYA,
    to: STATIONS.TOKYO,
    price: 10560,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[3],
  },
  {
    id: 46,
    from: STATIONS.NAGOYA,
    to: STATIONS.KYOTO,
    price: 5170,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[3],
  },
  {
    id: 47,
    from: STATIONS.NAGOYA,
    to: STATIONS.ODAWARA,
    price: 8570,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[3],
  },
  {
    id: 48,
    from: STATIONS.NAGOYA,
    to: STATIONS.SHIN_OSAKA,
    price: 5940,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[3],
  },
  {
    id: 49,
    from: STATIONS.NAGOYA,
    to: STATIONS.TAKAYAMA,
    price: 5610,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[3],
  },
  {
    id: 50,
    from: STATIONS.NAGOYA,
    to: STATIONS.SHINAGAWA,
    price: 10560,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[3],
  },
  {
    id: 51,
    from: STATIONS.NAGOYA,
    to: STATIONS.MATSUMOTO,
    price: 5610,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[3],
  },
  {
    id: 52,
    from: STATIONS.NAGANO,
    to: STATIONS.TOKYO,
    price: 7590,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[4],
  },
  {
    id: 53,
    from: STATIONS.NAGANO,
    to: STATIONS.UENO,
    price: 7600,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[4],
  },
  {
    id: 54,
    from: STATIONS.NAGANO,
    to: STATIONS.KANAZAWA,
    price: 8590,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[4],
  },
  {
    id: 55,
    from: STATIONS.NAGANO,
    to: STATIONS.NAGOYA,
    price: 6930,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[4],
  },
  {
    id: 56,
    from: STATIONS.NAGANO,
    to: STATIONS.OMIYA,
    price: 6050,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[4],
  },
  {
    id: 57,
    from: STATIONS.NAGANO,
    to: STATIONS.TSURUGA,
    price: 11340,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[4],
  },
  {
    id: 58,
    from: STATIONS.NAGANO,
    to: STATIONS.TAKASAKI,
    price: 4620,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[4],
  },
  {
    id: 59,
    from: STATIONS.NAGANO,
    to: STATIONS.IIYAMA,
    price: 1390,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[4],
  },
  {
    id: 60,
    from: STATIONS.NAGANO,
    to: STATIONS.MATSUMOTO,
    price: 2550,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[4],
  },
  {
    id: 61,
    from: STATIONS.NAGANO,
    to: STATIONS.TOYAMA,
    price: 6710,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[4],
  },
  {
    id: 62,
    from: STATIONS.NAGANO,
    to: STATIONS.KARUIZAWA,
    price: 3210,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[4],
  },
  {
    id: 63,
    from: STATIONS.SHIN_HAKODATE,
    to: STATIONS.TOKYO,
    price: 23630,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[5],
  },
  {
    id: 64,
    from: STATIONS.SHIN_HAKODATE,
    to: STATIONS.OSHAMAMBE,
    price: 3780,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[5],
  },
  {
    id: 65,
    from: STATIONS.SHIN_HAKODATE,
    to: STATIONS.HACHINOHE,
    price: 11220,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[5],
  },
  {
    id: 66,
    from: STATIONS.SHIN_HAKODATE,
    to: STATIONS.OMIYA,
    price: 22670,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[5],
  },
  {
    id: 67,
    from: STATIONS.SHIN_HAKODATE,
    to: STATIONS.SAPPORO,
    price: 9440,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[5],
  },
  {
    id: 68,
    from: STATIONS.SHIN_HAKODATE,
    to: STATIONS.SENDAI,
    price: 18150,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[5],
  },
  {
    id: 69,
    from: STATIONS.SHIN_HAKODATE,
    to: STATIONS.SHIN_AOMORI,
    price: 7920,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[5],
  },
  {
    id: 70,
    from: STATIONS.SHIN_HAKODATE,
    to: STATIONS.MORIOKA,
    price: 13640,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[5],
  },
  {
    id: 71,
    from: STATIONS.SHIN_HAKODATE,
    to: STATIONS.SHIN_SAPPORO,
    price: 9220,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[5],
  },
  {
    id: 72,
    from: STATIONS.UENO,
    to: STATIONS.SHIN_OSAKA,
    price: 13870,
    type: TRAIN_TYPE.SHINKANSEN,
  },
  {
    id: 73,
    from: STATIONS.SHIN_YOKOHAMA,
    to: STATIONS.SHIN_OSAKA,
    price: 13540,
    type: TRAIN_TYPE.SHINKANSEN,
  },
  {
    id: 74,
    from: STATIONS.MISHIMA,
    to: STATIONS.KYOTO,
    price: 10780,
    type: TRAIN_TYPE.SHINKANSEN,
  },
  {
    id: 75,
    from: STATIONS.ODAWARA,
    to: STATIONS.KYOTO,
    price: 11770,
    type: TRAIN_TYPE.SHINKANSEN,
  },
  {
    id: 76,
    from: STATIONS.KANAZAWA,
    to: STATIONS.TSURUGA,
    price: 4950,
    type: TRAIN_TYPE.SHINKANSEN,
  },
  {
    id: 77,
    from: STATIONS.TSURUGA,
    to: STATIONS.KYOTO,
    price: 3620,
    type: TRAIN_TYPE.SHINKANSEN,
  },
  {
    id: 78,
    from: STATIONS.KYOTO,
    to: STATIONS.OSAKA,
    price: 1340,
    type: TRAIN_TYPE.SHINKANSEN,
    area: AREAS[1],
  },
];

export const POPULAR_ROUTES: { [key: string]: IRoute[] } = {
  default: [
    ROUTES[0],
    ROUTES[1],
    ROUTES[11],
    ROUTES[17],
    ROUTES[74],
    ROUTES[75],
    ROUTES[40],
    ROUTES[2],
    ROUTES[12],
    ROUTES[76],
    ROUTES[19],
    ROUTES[45],
    ROUTES[21],
    ROUTES[77],
    ROUTES[30],
    ROUTES[13],
    ROUTES[23],
    ROUTES[6],
    ROUTES[78],
    ROUTES[36],
    ROUTES[46],
    ROUTES[4],
    ROUTES[22],
    ROUTES[14],
  ],
  tokyo: [ROUTES[0], ROUTES[1], ROUTES[2], ROUTES[3]],
  osaka: [ROUTES[11], ROUTES[12], ROUTES[13], ROUTES[6]],
  kyoto: [ROUTES[17], ROUTES[20], ROUTES[19], ROUTES[22]],
  nagoya: [ROUTES[45], ROUTES[46], ROUTES[47], ROUTES[48]],
  nagano: [ROUTES[52], ROUTES[53], ROUTES[54], ROUTES[55]],
  hokkaido: [ROUTES[63], ROUTES[64], ROUTES[65], ROUTES[66]],
  tokyo_osaka: [ROUTES[0], ROUTES[4], ROUTES[72], ROUTES[73]],
  tokyo_kyoto: [ROUTES[1], ROUTES[17], ROUTES[19], ROUTES[40]],
  tokyo_nagoya: [ROUTES[2], ROUTES[42], ROUTES[45], ROUTES[50]],
  tokyo_nagano: [ROUTES[3], ROUTES[38], ROUTES[52], ROUTES[53]],
  tokyo_miyagi: [ROUTES[33], ROUTES[68]],
  tokyo_kanagawa: [ROUTES[34]],
  tokyo_niigata: [ROUTES[35], ROUTES[39]],
  tokyo_chiba: [ROUTES[27], ROUTES[28], ROUTES[41]],
  tokyo_ishikawa: [ROUTES[30]],
  tokyo_hokkaido: [ROUTES[37], ROUTES[63]],
  tokyo_yamanashi: [ROUTES[29]],
  osaka_kyoto: [ROUTES[6], ROUTES[21]],
  osaka_fukui: [ROUTES[7]],
  osaka_ishikawa: [ROUTES[8]],
  osaka_gifu: [ROUTES[9]],
  osaka_hyogo: [ROUTES[10]],
  osaka_hiroshima: [ROUTES[12]],
  osaka_nagoya: [ROUTES[14], ROUTES[48]],
  osaka_kanagawa: [ROUTES[15], ROUTES[73]],
  osaka_shizuoka: [ROUTES[16]],
  kyoto_shizuoka: [ROUTES[18]],
  kyoto_kanagawa: [ROUTES[20], ROUTES[25]],
  kyoto_nagoya: [ROUTES[22], ROUTES[46]],
  kyoto_hiroshima: [ROUTES[23]],
  kyoto_fukui: [ROUTES[24]],
  kyoto_okayama: [ROUTES[26]],
  nagoya_kanagawa: [ROUTES[47]],
  nagoya_gifu: [ROUTES[49]],
  nagoya_nagano: [ROUTES[55], ROUTES[51]],
  nagano_ishikawa: [ROUTES[54]],
  nagano_nagoya: [ROUTES[51], ROUTES[55], ROUTES[59]],
  nagano_saitama: [ROUTES[56]],
  nagano_fukui: [ROUTES[57]],
  nagano_gunma: [ROUTES[58]],
  nagano_toyama: [ROUTES[61]],
  hokkaido_aomori: [ROUTES[69]],
  hokkaido_saitama: [ROUTES[66]],
  hokkaido_miyagi: [ROUTES[68]],
  hokkaido_iwate: [ROUTES[70]],
};
