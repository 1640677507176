import { IContactFormRequest } from "@/types";
import axios from "axios";
import { createClient } from "microcms-js-sdk";

const baseURL = process.env.NEXT_PUBLIC_ORIGIN_URL;
const microCmsUrl = process.env.NEXT_PUBLIC_MICROCMS_URL;
const microCmsKey = process.env.NEXT_PUBLIC_MICROCMS_API_KEY;

const httpClient = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
  auth: {
    username: process.env.NEXT_PUBLIC_BASIC_AUTH_USER!,
    password: process.env.NEXT_PUBLIC_BASIC_AUTH_PW!,
  },
});

export const microCmsClient = createClient({
  serviceDomain: microCmsUrl || "",
  apiKey: microCmsKey || "",
});

/**
 * Currency Rates API
 * Retrieves the latest currency exchange rates from the API.
 */
export async function fetchCurrencyRates() {
  const data = await httpClient.get("/api/get-exchange-rates");

  return data.data;
}

/**
 * Contact Form API
 * Handles the submission of contact form data.
 *
 * @request <IContactFormRequest>
 * @return <IContactFormResponse>
 */
export async function submitContactForm(params: IContactFormRequest) {
  const { data } = await httpClient.post("/api/contact", params, {
    baseURL: baseURL,
  });

  return data;
}

/**
 * Station free word search API
 * Retrieves stations based on requested string.
 *
 */
export async function searchStations(params: { locale: string; word: string }) {
  const data = await httpClient.get(
    `/${params.locale}/booking/ajax/freewordSearch/jr/`,
    {
      params: { word: params.word },
    }
  );

  return data.data;
}
