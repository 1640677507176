export const TYPE = {
  LANG: 1,
  CURRENCY: 2,
};

export const LANG = [
  {
    key: "en",
    label: "English",
  },
  {
    key: "zh-tw",
    label: "繁體中文",
  },
  {
    key: "zh-cn",
    label: "简体中文",
  },
  {
    key: "ko",
    label: "한국어",
  },
  {
    key: "es",
    label: "Español",
  },
  {
    key: "fr",
    label: "Français",
  },
  {
    key: "id",
    label: "Bahasa Indonesia",
  },
  {
    key: "vi",
    label: "Tiếng Việt",
  },
];

export const CURRENCIES = [
  {
    key: "JPY",
    symbol: "¥",
    label: "Japanese yen",
  },
  {
    key: "USD",
    symbol: "$",
    label: "US dollar",
  },
  {
    key: "AUD",
    symbol: "$",
    label: "Australian dollar",
  },
  {
    key: "EUR",
    symbol: "€",
    label: "Euro",
  },
  {
    key: "TWD",
    symbol: "$",
    label: "New Taiwan dollar",
  },
  {
    key: "KRW",
    symbol: "₩",
    label: "South Korean won",
  },
  {
    key: "MYR",
    symbol: "RM",
    label: "Malaysian ringgit",
  },
  {
    key: "THB",
    symbol: "฿",
    label: "Thai Baht",
  },
  {
    key: "VND",
    symbol: "₫",
    label: "Vietnamese dong",
  },
  {
    key: "CNH",
    symbol: "¥",
    label: "Chinese yuan",
  },
  {
    key: "HKD",
    symbol: "$",
    label: "Hong Kong dollar",
  },
];
