import { IAccessInfo, IInfoGraphic } from "@/types";
import { CITIES, LOCATION_TYPE, PREFECTURES } from ".";

export const AREAS: string[] = [
  PREFECTURES[41],
  PREFECTURES[33],
  PREFECTURES[22],
  CITIES[1],
  PREFECTURES[26],
  PREFECTURES[12],
];

export const ROUTE_TYPE: { [key: string]: string } = {
  AREA: "area",
  ROUTE: "route",
};

export const AREA_ROUTES: [string, string][] = [
  [PREFECTURES[41], PREFECTURES[33]],
  [PREFECTURES[41], PREFECTURES[22]],
  [PREFECTURES[41], CITIES[1]],
  [PREFECTURES[41], PREFECTURES[26]],
  [PREFECTURES[41], PREFECTURES[24]],
  [PREFECTURES[41], PREFECTURES[19]],
  [PREFECTURES[41], PREFECTURES[29]],
  [PREFECTURES[41], PREFECTURES[4]],
  [PREFECTURES[41], PREFECTURES[15]],
  [PREFECTURES[41], PREFECTURES[12]],
  [PREFECTURES[41], PREFECTURES[47]],
  [PREFECTURES[33], PREFECTURES[22]],
  [PREFECTURES[33], PREFECTURES[6]],
  [PREFECTURES[33], PREFECTURES[15]],
  [PREFECTURES[33], PREFECTURES[9]],
  [PREFECTURES[33], PREFECTURES[13]],
  [PREFECTURES[33], PREFECTURES[11]],
  [PREFECTURES[33], CITIES[1]],
  [PREFECTURES[33], PREFECTURES[19]],
  [PREFECTURES[33], PREFECTURES[38]],
  [PREFECTURES[22], PREFECTURES[38]],
  [PREFECTURES[22], PREFECTURES[19]],
  [PREFECTURES[22], CITIES[1]],
  [PREFECTURES[22], PREFECTURES[11]],
  [PREFECTURES[22], PREFECTURES[6]],
  [PREFECTURES[22], PREFECTURES[31]],
  [CITIES[1], PREFECTURES[19]],
  [CITIES[1], PREFECTURES[9]],
  [CITIES[1], PREFECTURES[26]],
  [PREFECTURES[26], PREFECTURES[15]],
  [PREFECTURES[26], CITIES[1]],
  [PREFECTURES[26], PREFECTURES[35]],
  [PREFECTURES[26], PREFECTURES[6]],
  [PREFECTURES[26], PREFECTURES[10]],
  [PREFECTURES[26], PREFECTURES[43]],
  [PREFECTURES[12], PREFECTURES[3]],
  [PREFECTURES[12], PREFECTURES[35]],
  [PREFECTURES[12], PREFECTURES[24]],
  [PREFECTURES[12], PREFECTURES[16]],
];

export const AREA_INFO: { [key: string]: IInfoGraphic } = {
  tokyo_osaka: {
    first_departure: "06:00",
    last_departure: "21:24",
    avg_travel_time: 140,
    avg_daily_departure: 154,
    fare: 16100,
    frequency: "5-10",
  },
  tokyo_kyoto: {
    first_departure: "06:00",
    last_departure: "21:24",
    avg_travel_time: 130,
    avg_daily_departure: 154,
    fare: 15550,
    frequency: "5-10",
  },
  tokyo_nagoya: {
    first_departure: "06:00",
    last_departure: "22:00",
    avg_travel_time: 100,
    avg_daily_departure: 176,
    fare: 12790,
    frequency: "5-10",
  },
  tokyo_nagano: {
    first_departure: "06:16",
    last_departure: "22:08",
    avg_travel_time: 85,
    avg_daily_departure: 48,
    fare: 10040,
    frequency: "15-30",
  },
  tokyo_miyagi: {
    first_departure: "06:04",
    last_departure: "21:44",
    avg_travel_time: 90,
    avg_daily_departure: 72,
    fare: 12790,
    frequency: "10-30",
  },
  tokyo_kanagawa: {
    first_departure: "06:21",
    last_departure: "22:48",
    avg_travel_time: 33,
    avg_daily_departure: 43,
    fare: 5510,
    frequency: "10-30",
  },
  tokyo_niigata: {
    first_departure: "06:08",
    last_departure: "21:20",
    avg_travel_time: 90,
    avg_daily_departure: 24,
    fare: 12460,
    frequency: "20-60",
  },
  tokyo_chiba: {
    first_departure: "06:18",
    last_departure: "20:03",
    avg_travel_time: 60,
    avg_daily_departure: 27,
    fare: 5300,
    frequency: "30-60",
  },
  tokyo_ishikawa: {
    first_departure: "06:16",
    last_departure: "21:04",
    avg_travel_time: 150,
    avg_daily_departure: 30,
    fare: 16080,
    frequency: "20-60",
  },
  tokyo_hokkaido: {
    first_departure: "06:32",
    last_departure: "19:20",
    avg_travel_time: 237,
    avg_daily_departure: 10,
    fare: 25660,
    frequency: "60-120",
  },
  tokyo_yamanashi: {
    first_departure: "0",
    last_departure: "0",
    avg_travel_time: 0,
    avg_daily_departure: 0,
    fare: 0,
    frequency: "0",
  },
  osaka_kyoto: {
    first_departure: "06:00",
    last_departure: "22:30",
    avg_travel_time: 13,
    avg_daily_departure: 171,
    fare: 3570,
    frequency: "3-15",
  },
  osaka_fukui: {
    first_departure: "06:34",
    last_departure: "20:58",
    avg_travel_time: 80,
    avg_daily_departure: 27,
    fare: 6930,
    frequency: "30-60",
  },
  osaka_ishikawa: {
    first_departure: "06:34",
    last_departure: "19:14",
    avg_travel_time: 150,
    avg_daily_departure: 20,
    fare: 14110,
    frequency: "30-60",
  },
  osaka_gifu: {
    first_departure: "08:02",
    last_departure: "08:02",
    avg_travel_time: 140,
    avg_daily_departure: 1,
    fare: 10150,
    frequency: "-",
  },
  osaka_hyogo: {
    first_departure: "08:06",
    last_departure: "14:05",
    avg_travel_time: 170,
    avg_daily_departure: 5,
    fare: 8370,
    frequency: "60-120",
  },
  osaka_hiroshima: {
    first_departure: "06:00",
    last_departure: "22:29",
    avg_travel_time: 85,
    avg_daily_departure: 97,
    fare: 12120,
    frequency: "3-10",
  },
  osaka_nagoya: {
    first_departure: "06:00",
    last_departure: "22:30",
    avg_travel_time: 49,
    avg_daily_departure: 171,
    fare: 8170,
    frequency: "3-15",
  },
  osaka_kanagawa: {
    first_departure: "07:54",
    last_departure: "20:45",
    avg_travel_time: 140,
    avg_daily_departure: 19,
    fare: 14550,
    frequency: "30-60",
  },
  osaka_shizuoka: {
    first_departure: "07:36",
    last_departure: "20:42",
    avg_travel_time: 129,
    avg_daily_departure: 20,
    fare: 14000,
    frequency: "10-60",
  },
  kyoto_shizuoka: {
    first_departure: "07:51",
    last_departure: "20:56",
    avg_travel_time: 125,
    avg_daily_departure: 20,
    fare: 13010,
    frequency: "5-60",
  },
  kyoto_kanagawa: {
    first_departure: "08:10",
    last_departure: "20:59",
    avg_travel_time: 125,
    avg_daily_departure: 19,
    fare: 14000,
    frequency: "30-60",
  },
  kyoto_nagoya: {
    first_departure: "06:14",
    last_departure: "22:46",
    avg_travel_time: 35,
    avg_daily_departure: 171,
    fare: 7400,
    frequency: "3-10",
  },
  kyoto_hiroshima: {
    first_departure: "06:55",
    last_departure: "22:14",
    avg_travel_time: 97,
    avg_daily_departure: 60,
    fare: 13000,
    frequency: "10-30",
  },
  kyoto_fukui: {
    first_departure: "06:59",
    last_departure: "21:21",
    avg_travel_time: 55,
    avg_daily_departure: 27,
    fare: 5650,
    frequency: "30-60",
  },
  kyoto_okayama: {
    first_departure: "06:55",
    last_departure: "22:14",
    avg_travel_time: 60,
    avg_daily_departure: 74,
    fare: 9370,
    frequency: "10-30",
  },
  nagoya_kanagawa: {
    first_departure: "06:45",
    last_departure: "21:39",
    avg_travel_time: 67,
    avg_daily_departure: 35,
    fare: 10800,
    frequency: "30-50",
  },
  nagoya_gifu: {
    first_departure: "07:43",
    last_departure: "20:17",
    avg_travel_time: 150,
    avg_daily_departure: 10,
    fare: 7840,
    frequency: "60-130",
  },
  nagoya_nagano: {
    first_departure: "07:00",
    last_departure: "19:40",
    avg_travel_time: 185,
    avg_daily_departure: 13,
    fare: 9160,
    frequency: "60-100",
  },
  nagano_ishikawa: {
    first_departure: "06:11",
    last_departure: "22:25",
    avg_travel_time: 66,
    avg_daily_departure: 31,
    fare: 10820,
    frequency: "40-100",
  },
  nagano_nagoya: {
    first_departure: "06:09",
    last_departure: "19:40",
    avg_travel_time: 185,
    avg_daily_departure: 13,
    fare: 9160,
    frequency: "40-90",
  },
  nagano_saitama: {
    first_departure: "06:02",
    last_departure: "22:11",
    avg_travel_time: 70,
    avg_daily_departure: 50,
    fare: 8280,
    frequency: "15-30",
  },
  nagano_fukui: {
    first_departure: "07:38",
    last_departure: "21:17",
    avg_travel_time: 69,
    avg_daily_departure: 14,
    fare: 14100,
    frequency: "60-75",
  },
  nagano_gunma: {
    first_departure: "06:02",
    last_departure: "21:49",
    avg_travel_time: 40,
    avg_daily_departure: 33,
    fare: 6850,
    frequency: "15-60",
  },
  nagano_toyama: {
    first_departure: "06:11",
    last_departure: "22:25",
    avg_travel_time: 48,
    avg_daily_departure: 31,
    fare: 8940,
    frequency: "40-100",
  },
  hokkaido_aomori: {
    first_departure: "06:39",
    last_departure: "21:57",
    avg_travel_time: 57,
    avg_daily_departure: 13,
    fare: 9950,
    frequency: "60-120",
  },
  hokkaido_saitama: {
    first_departure: "06:39",
    last_departure: "18:40",
    avg_travel_time: 215,
    avg_daily_departure: 10,
    fare: 24700,
    frequency: "60-90",
  },
  hokkaido_miyagi: {
    first_departure: "06:39",
    last_departure: "19:41",
    avg_travel_time: 155,
    avg_daily_departure: 11,
    fare: 20180,
    frequency: "60-120",
  },
  hokkaido_iwate: {
    first_departure: "06:39",
    last_departure: "20:43",
    avg_travel_time: 115,
    avg_daily_departure: 12,
    fare: 15670,
    frequency: "60-90",
  },
};

export const STATION_ACCESS: { [key: string]: IAccessInfo[] } = {
  tokyo: [
    {
      from: "tokyo.narita",
      from_type: LOCATION_TYPE.LANDMARK,
      to: "tokyo.tokyo",
      to_type: LOCATION_TYPE.STATION,
      bus: [90, 120],
      shinkansen: [0],
      car: [60, 90],
      train: [60, 90],
    },
    {
      from: "tokyo.narita",
      from_type: LOCATION_TYPE.LANDMARK,
      to: "tokyo.shinagawa",
      to_type: LOCATION_TYPE.STATION,
      bus: [90, 150],
      shinkansen: [0],
      car: [50, 70],
      train: [60, 95],
    },
    {
      from: "tokyo.narita",
      from_type: LOCATION_TYPE.LANDMARK,
      to: "tokyo.ueno",
      to_type: LOCATION_TYPE.STATION,
      bus: [90, 125],
      shinkansen: [0],
      car: [60, 70],
      train: [52, 80],
    },
    {
      from: "tokyo.narita",
      from_type: LOCATION_TYPE.LANDMARK,
      to: "tokyo.shinjuku",
      to_type: LOCATION_TYPE.STATION,
      bus: [120],
      shinkansen: [0],
      car: [90],
      train: [75, 120],
    },
    {
      from: "tokyo.narita",
      from_type: LOCATION_TYPE.LANDMARK,
      to: "tokyo.ikebukuro",
      to_type: LOCATION_TYPE.STATION,
      bus: [100, 130],
      shinkansen: [0],
      car: [90, 100],
      train: [60, 90],
    },
    {
      from: "tokyo.narita",
      from_type: LOCATION_TYPE.LANDMARK,
      to: "tokyo.shibuya",
      to_type: LOCATION_TYPE.STATION,
      bus: [100, 130],
      shinkansen: [0],
      car: [75, 90],
      train: [90, 120],
    },
    {
      from: "tokyo.haneda",
      from_type: LOCATION_TYPE.LANDMARK,
      to: "tokyo.tokyo",
      to_type: LOCATION_TYPE.STATION,
      bus: [40, 60],
      shinkansen: [0],
      car: [40, 50],
      train: [30, 40],
    },
    {
      from: "tokyo.haneda",
      from_type: LOCATION_TYPE.LANDMARK,
      to: "tokyo.shinagawa",
      to_type: LOCATION_TYPE.STATION,
      bus: [40, 50],
      shinkansen: [0],
      car: [25, 30],
      train: [20],
    },
    {
      from: "tokyo.haneda",
      from_type: LOCATION_TYPE.LANDMARK,
      to: "tokyo.ueno",
      to_type: LOCATION_TYPE.STATION,
      bus: [60],
      shinkansen: [0],
      car: [50, 60],
      train: [40, 55],
    },
    {
      from: "tokyo.haneda",
      from_type: LOCATION_TYPE.LANDMARK,
      to: "tokyo.shinjuku",
      to_type: LOCATION_TYPE.STATION,
      bus: [45],
      shinkansen: [0],
      car: [50, 70],
      train: [50, 60],
    },
    {
      from: "tokyo.haneda",
      from_type: LOCATION_TYPE.LANDMARK,
      to: "tokyo.ikebukuro",
      to_type: LOCATION_TYPE.STATION,
      bus: [60],
      shinkansen: [0],
      car: [60, 70],
      train: [50, 70],
    },
    {
      from: "tokyo.haneda",
      from_type: LOCATION_TYPE.LANDMARK,
      to: "tokyo.shibuya",
      to_type: LOCATION_TYPE.STATION,
      bus: [60],
      shinkansen: [0],
      car: [50, 60],
      train: [40, 60],
    },
  ],
  osaka: [
    {
      from: "osaka.osaka",
      from_type: LOCATION_TYPE.STATION,
      to: "kyoto.kyoto",
      to_type: LOCATION_TYPE.LANDMARK,
      bus: [60],
      shinkansen: [15, 30],
      car: [60],
      train: [30, 60],
    },
    {
      from: "osaka.osaka",
      from_type: LOCATION_TYPE.STATION,
      to: "hyogo.kobe",
      to_type: LOCATION_TYPE.LANDMARK,
      bus: [90, 120],
      shinkansen: [30, 45],
      car: [90, 120],
      train: [50, 70],
    },
    {
      from: "osaka.osaka",
      from_type: LOCATION_TYPE.STATION,
      to: "nara.nara",
      to_type: LOCATION_TYPE.LANDMARK,
      bus: [60, 90],
      shinkansen: [0],
      car: [90, 120],
      train: [35, 60],
    },
    {
      from: "osaka.osaka",
      from_type: LOCATION_TYPE.STATION,
      to: "osaka.osaka_castle",
      to_type: LOCATION_TYPE.LANDMARK,
      bus: [20, 30],
      shinkansen: [0],
      car: [20, 30],
      train: [10, 15],
    },
    {
      from: "osaka.osaka",
      from_type: LOCATION_TYPE.STATION,
      to: "osaka.dotonbori",
      to_type: LOCATION_TYPE.LANDMARK,
      bus: [30, 40],
      shinkansen: [0],
      car: [15, 20],
      train: [5, 10],
    },
    {
      from: "osaka.osaka",
      from_type: LOCATION_TYPE.STATION,
      to: "osaka.usj",
      to_type: LOCATION_TYPE.LANDMARK,
      bus: [30, 40],
      shinkansen: [0],
      car: [30, 40],
      train: [15, 20],
    },
  ],
  kyoto: [
    {
      from: "kyoto.kyoto",
      from_type: LOCATION_TYPE.STATION,
      to: "osaka.osaka",
      to_type: LOCATION_TYPE.LANDMARK,
      bus: [60, 90],
      shinkansen: [15, 30],
      car: [60],
      train: [30, 60],
    },
    {
      from: "kyoto.kyoto",
      from_type: LOCATION_TYPE.STATION,
      to: "hyogo.kobe",
      to_type: LOCATION_TYPE.LANDMARK,
      bus: [90, 120],
      shinkansen: [30, 45],
      car: [90, 120],
      train: [50, 70],
    },
    {
      from: "kyoto.kyoto",
      from_type: LOCATION_TYPE.STATION,
      to: "nara.nara",
      to_type: LOCATION_TYPE.LANDMARK,
      bus: [60, 90],
      shinkansen: [15, 30],
      car: [60],
      train: [30, 60],
    },
    {
      from: "kyoto.kyoto",
      from_type: LOCATION_TYPE.STATION,
      to: "kyoto.kiyomizudera",
      to_type: LOCATION_TYPE.LANDMARK,
      bus: [15, 20],
      shinkansen: [0],
      car: [20, 25],
      train: [10, 15],
    },
    {
      from: "kyoto.kyoto",
      from_type: LOCATION_TYPE.STATION,
      to: "kyoto.fushimi_inari",
      to_type: LOCATION_TYPE.LANDMARK,
      bus: [30, 40],
      shinkansen: [0],
      car: [10, 15],
      train: [5, 10],
    },
  ],
  nagoya: [
    {
      from: "aichi.nagoya",
      from_type: LOCATION_TYPE.STATION,
      to: "osaka.osaka",
      to_type: LOCATION_TYPE.LANDMARK,
      bus: [180, 240],
      shinkansen: [50, 60],
      car: [120, 150],
      train: [120, 150],
    },
    {
      from: "aichi.nagoya",
      from_type: LOCATION_TYPE.STATION,
      to: "kyoto.kyoto",
      to_type: LOCATION_TYPE.LANDMARK,
      bus: [120, 180],
      shinkansen: [35, 40],
      car: [90, 120],
      train: [60, 90],
    },
    {
      from: "aichi.nagoya",
      from_type: LOCATION_TYPE.STATION,
      to: "ishikawa.kanazawa",
      to_type: LOCATION_TYPE.LANDMARK,
      bus: [150],
      shinkansen: [0],
      car: [180, 210],
      train: [180],
    },
    {
      from: "aichi.nagoya",
      from_type: LOCATION_TYPE.STATION,
      to: "aichi.osu_shopping_district",
      to_type: LOCATION_TYPE.LANDMARK,
      bus: [15, 20],
      shinkansen: [0],
      car: [10, 15],
      train: [10, 15],
    },
    {
      from: "aichi.nagoya",
      from_type: LOCATION_TYPE.STATION,
      to: "aichi.nagoya_castle",
      to_type: LOCATION_TYPE.LANDMARK,
      bus: [15, 20],
      shinkansen: [0],
      car: [10, 15],
      train: [10, 15],
    },
  ],
  hokkaido: [
    {
      from: "hokkaido.shin_hakodate",
      from_type: LOCATION_TYPE.STATION,
      to: "hokkaido.sapporo",
      to_type: LOCATION_TYPE.LANDMARK,
      bus: [240, 300],
      shinkansen: [180],
      car: [210, 240],
      train: [270, 300],
    },
    {
      from: "hokkaido.shin_hakodate",
      from_type: LOCATION_TYPE.STATION,
      to: "hokkaido.hakodate",
      to_type: LOCATION_TYPE.LANDMARK,
      bus: [30, 40],
      shinkansen: [0],
      car: [30, 40],
      train: [15, 20],
    },
    {
      from: "hokkaido.shin_hakodate",
      from_type: LOCATION_TYPE.STATION,
      to: "hokkaido.otaru",
      to_type: LOCATION_TYPE.LANDMARK,
      bus: [180, 240],
      shinkansen: [0],
      car: [120, 150],
      train: [150, 180],
    },
    {
      from: "hokkaido.shin_hakodate",
      from_type: LOCATION_TYPE.STATION,
      to: "hokkaido.asahikawa",
      to_type: LOCATION_TYPE.LANDMARK,
      bus: [240, 300],
      shinkansen: [0],
      car: [180, 210],
      train: [150, 180],
    },
  ],
  nagano: [
    {
      from: "nagano.nagano",
      from_type: LOCATION_TYPE.STATION,
      to: "nagano.kamikochi",
      to_type: LOCATION_TYPE.STATION,
      bus: [150, 180],
      shinkansen: [0],
      car: [150, 180],
      train: [210, 240],
    },
    {
      from: "nagano.nagano",
      from_type: LOCATION_TYPE.STATION,
      to: "nagano.karuizawa",
      to_type: LOCATION_TYPE.STATION,
      bus: [90, 120],
      shinkansen: [30, 40],
      car: [90, 120],
      train: [90, 120],
    },
    {
      from: "nagano.nagano",
      from_type: LOCATION_TYPE.STATION,
      to: "gifu.shirakawago",
      to_type: LOCATION_TYPE.STATION,
      bus: [240, 300],
      shinkansen: [0],
      car: [240, 300],
      train: [240, 300],
    },
  ],
};
