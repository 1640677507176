import { IStation } from "@/types";
import { PREFECTURES } from ".";

/**
 * The name used in this object are based on the object name being
 * used in locale/stations.json. Please be careful when changing the
 * values of the names in this constant.
 */
export const STATIONS: { [key: string]: IStation } = {
  TOKYO: {
    prefecture: PREFECTURES[41],
    name: "tokyo",
    code: "00006668",
  },
  SHIN_OSAKA: {
    prefecture: PREFECTURES[33],
    name: "shin_osaka",
    code: "00004305",
  },
  KYOTO: {
    prefecture: PREFECTURES[22],
    name: "kyoto",
    code: "00001756",
  },
  NARITA_T1: {
    prefecture: PREFECTURES[41],
    name: "narita_t1",
    code: "00004637",
  },
  KANSAI_AIRPORT: {
    prefecture: PREFECTURES[33],
    name: "kansai_airport",
    code: "00001353",
  },
  SHINAGAWA: {
    prefecture: PREFECTURES[41],
    name: "shinagawa",
    code: "00007825",
  },
  NAGOYA: {
    prefecture: PREFECTURES[1],
    name: "nagoya",
    code: "00008576",
  },
  HIROSHIMA: {
    prefecture: PREFECTURES[11],
    name: "hiroshima",
    code: "00002397",
  },
  HAKATA: {
    prefecture: PREFECTURES[7],
    name: "hakata",
    code: "00007420",
  },
  SHIN_HAKODATE: {
    prefecture: PREFECTURES[12],
    name: "shin_hakodate",
    code: "00006537",
  },
  OMIYA: {
    prefecture: PREFECTURES[35],
    name: "omiya",
    code: "00005564",
  },
  SHIN_YOKOHAMA: {
    prefecture: PREFECTURES[19],
    name: "shin_yokohama",
    code: "00004179",
  },
  ATAMI: {
    prefecture: PREFECTURES[38],
    name: "atami",
    code: "00007326",
  },
  SHIN_FUJI: {
    prefecture: PREFECTURES[38],
    name: "shin_fuji",
    code: "00004358",
  },
  SENDAI: {
    prefecture: PREFECTURES[24],
    name: "sendai",
    code: "00005125",
  },
  MORIOKA: {
    prefecture: PREFECTURES[16],
    name: "morioka",
    code: "00004689",
  },
  NAGANO: {
    prefecture: PREFECTURES[26],
    name: "nagano",
    code: "00006275",
  },
  KANAZAWA: {
    prefecture: PREFECTURES[15],
    name: "kanazawa",
    code: "00001888",
  },
  OKAYAMA: {
    prefecture: PREFECTURES[31],
    name: "okayama",
    code: "00000859",
  },
  KAGOSHIMA_CHUO: {
    prefecture: PREFECTURES[18],
    name: "kagoshima_chuo",
    code: "00004786",
  },
  SAPPORO: {
    prefecture: PREFECTURES[12],
    name: "sapporo",
    code: "00002928",
  },
  SHINJUKU: {
    prefecture: PREFECTURES[41],
    name: "shinjuku",
    code: "00004254",
  },
  KAWAGUCHIKO: {
    prefecture: PREFECTURES[19],
    name: "kawaguchiko",
    code: "00001065",
  },
  HAKODATE: {
    prefecture: PREFECTURES[12],
    name: "hakodate",
    code: "00007485",
  },
  UENO: {
    prefecture: PREFECTURES[41],
    name: "ueno",
    code: "00004067",
  },
  ODAWARA: {
    prefecture: PREFECTURES[19],
    name: "odawara",
    code: "00003742",
  },
  MATSUMOTO: {
    prefecture: PREFECTURES[26],
    name: "matsumoto",
    code: "00003876",
  },
  KARUIZAWA: {
    prefecture: PREFECTURES[26],
    name: "karuizawa",
    code: "00002048",
  },
  HAKUBA: {
    prefecture: PREFECTURES[26],
    name: "hakuba",
    code: "00007473",
  },
  IIYAMA: {
    prefecture: PREFECTURES[26],
    name: "iiyama",
    code: "00007617",
  },
  TOYAMA: {
    prefecture: PREFECTURES[43],
    name: "toyama",
    code: "00007872",
  },
  TAKAYAMA: {
    prefecture: PREFECTURES[9],
    name: "takayama",
    code: "00002571",
  },
  ECHIGO_YUZAWA: {
    prefecture: PREFECTURES[29],
    name: "echigo_yuzawa",
    code: "00000702",
  },
  JOETSU_MYOKO: {
    prefecture: PREFECTURES[29],
    name: "joetsu_myoko",
    code: "00009136",
  },
  KORIYAMA: {
    prefecture: PREFECTURES[8],
    name: "koriyama",
    code: "00002012",
  },
  OSAKA: {
    prefecture: PREFECTURES[33],
    name: "osaka",
    code: "00005602",
  },
  TSURUGA: {
    prefecture: PREFECTURES[6],
    name: "tsuruga",
    code: "00006999",
  },
  KINOSAKIONSEN: {
    prefecture: PREFECTURES[13],
    name: "kinosakionsen",
    code: "00004089",
  },
  MISHIMA: {
    prefecture: PREFECTURES[38],
    name: "mishima",
    code: "00003056",
  },
  TAKASAKI: {
    prefecture: PREFECTURES[10],
    name: "takasaki",
    code: "00002569",
  },
  OSHAMAMBE: {
    prefecture: PREFECTURES[12],
    name: "oshamambe",
    code: "00006264",
  },
  HACHINOHE: {
    prefecture: PREFECTURES[3],
    name: "hachinohe",
    code: "00007520",
  },
  SHIN_AOMORI: {
    prefecture: PREFECTURES[3],
    name: "shin_aomori",
    code: "00004283",
  },
  SHIN_SAPPORO: {
    prefecture: PREFECTURES[12],
    name: "shin_sapporo",
    code: "00004237",
  },
  SHIN_KOBE: {
    prefecture: PREFECTURES[13],
    name: "shin_kobe",
    code: "00004269",
  },
};
